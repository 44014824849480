import { get } from "lodash";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import {
  EDIT_IMPORTER_FORM,
  ImporterDetailsEntity,
  ShipmentEntity,
} from "~/constants/forms";
import { InvoiceModels, ServiceModels, ShipmentModels } from "~/models";
import { ReferenceSelectors, UmsSelectors } from "~/redux";
import { getValue } from "~/utils/object";

export const getEditImporterFormValues = state =>
  getFormValues(EDIT_IMPORTER_FORM)(state);

export const getImporterSelectedCountry = createSelector(
  ReferenceSelectors.getCountries,
  getEditImporterFormValues,
  (countries, editImporterFormValues) =>
    countries.find(
      ({ countryKey }) =>
        countryKey ===
        get(editImporterFormValues, ImporterDetailsEntity.ADDRESS.COUNTRY_CODE)
    )
);

export const getRequiredFields = selectedService =>
  createSelector(
    getEditImporterFormValues,
    getImporterSelectedCountry,
    UmsSelectors.getCustomer,
    (editImporterFormValues, selectedCountry, customer) => {
      const countryCode = get(
        editImporterFormValues,
        ImporterDetailsEntity.ADDRESS.COUNTRY_CODE,
        ""
      );
      const notifyRequired = ServiceModels.isFlagRequired(
        selectedService,
        "notifyRequired"
      );
      const emailValue = get(
        editImporterFormValues,
        ImporterDetailsEntity.CONTACT_DETAILS.EMAIL,
        ""
      );
      const telephoneValue = get(
        editImporterFormValues,
        ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE,
        ""
      );
      const isPostcodeRequired =
        ShipmentModels.shouldCheckPostcode(selectedCountry);
      const isBusiness = get(
        editImporterFormValues,
        ImporterDetailsEntity.IS_BUSINESS
      );
      const hideOptions = ServiceModels.shouldHideBusinessConsumerOptions(
        selectedService,
        customer.shippingVersion
      );

      return {
        [ImporterDetailsEntity.ADDRESS.STREET]: true,
        [ImporterDetailsEntity.ADDRESS.COUNTRY_CODE]: true,
        // @see https://it.dpduk.live/it/diagram/diag_z4h9WV6GAqAADGAL.html?id=1627037965015
        [ImporterDetailsEntity.IS_BUSINESS]: !hideOptions,
        [ImporterDetailsEntity.ADDRESS.TOWN]: true,
        [ImporterDetailsEntity.CONTACT_DETAILS.TELEPHONE]:
          selectedService && notifyRequired && !(!telephoneValue && emailValue),
        [ImporterDetailsEntity.CONTACT_DETAILS.CONTACT_NAME]:
          selectedService && notifyRequired,
        [ImporterDetailsEntity.CONTACT_DETAILS.EMAIL]:
          selectedService && notifyRequired && !(!emailValue && telephoneValue),
        [ImporterDetailsEntity.ADDRESS.POSTCODE]:
          !!countryCode && isPostcodeRequired,
        [ImporterDetailsEntity.PID_NUMBER]:
          !hideOptions &&
          !isBusiness &&
          ServiceModels.isFlagRequired(selectedService, "pidRequired"),
        [ImporterDetailsEntity.VAT_NUMBER]:
          !hideOptions &&
          isBusiness &&
          ServiceModels.isFlagRequired(selectedService, "vatRequired"),
        [ImporterDetailsEntity.EORI_NUMBER]:
          !hideOptions &&
          isBusiness &&
          ServiceModels.isFlagRequired(selectedService, "eoriRequired"),
      };
    }
  );

export const getAllowedFields = createShipmentValues =>
  createSelector(
    getEditImporterFormValues,
    UmsSelectors.getCustomer,
    (formValues, customer) =>
      InvoiceModels.getImporterVisibleFields(customer, {
        isBusiness: getValue(
          formValues,
          ImporterDetailsEntity.IS_BUSINESS,
          false
        ),
        atRisk: getValue(formValues, ImporterDetailsEntity.AT_RISK, false),
        countryCode: getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        ),
        postcode: getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
          ""
        ),
      })
  );
