import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import { createSelector } from "reselect";

import { getSearchAddressDetails } from "~/components/SearchAddress/selectors";
import {
  DELIVERY_DETAILS_SEARCH_FORM,
  Fields,
  OUTBOUND_CONSIGNMENT,
  RETURN_DETAILS_SEARCH_FORM,
  ShipmentEntity,
} from "~/constants/forms";
import { ServiceModels, ShipmentModels } from "~/models";
import { ShipmentSelectors } from "~/pages/Shipment/redux";
import {
  AuthSelectors,
  ProfilesSelectors,
  ReferenceSelectors,
  UmsSelectors,
} from "~/redux";
import { getValue } from "~/utils/object";

export const getShipment = state => get(state, "ui.scanning.shipment.data", {});

export const getDisabledFields = createSelector(
  getShipment,
  UmsSelectors.getAdvancedCustomerConfiguration,
  ShipmentSelectors.getCustomsFieldsConfig,
  ReferenceSelectors.getActiveOutboundService,
  ShipmentSelectors.getOutboundServicesForCurrentUser,
  ShipmentSelectors.getShipmentFormSyncErrors,
  ShipmentSelectors.getShipmentFormSubmitErrors,
  ShipmentSelectors.getProductsKeyValue,
  ShipmentSelectors.getSelectedCountry,
  ShipmentSelectors.getGenerateCustomsSettings,
  ShipmentSelectors.getShipmentFormValues,
  ShipmentSelectors.getInboundProductValues,
  ShipmentSelectors.getInboundServiceValues,
  UmsSelectors.getCustomerPrefs,
  (
    shipment,
    { noManualAddressEntry, reference1ReadOnly, noManualContactDetailsEntry },
    { customsValueDisabled },
    selectedService,
    services,
    syncErrors,
    submitErrors,
    productsKeyValue,
    selectedCountry,
    { dropdownDisabled },
    formValues,
    returnProductValues,
    returnServiceValues,
    customerPrefs
  ) => {
    const isShipmentEmpty = isEmpty(shipment);
    const noFailureDetails = !isShipmentEmpty && !shipment.stagingShipmentId;

    const productCode = get(selectedService, "product.productKey");
    const servicesKeyValue = ServiceModels.getProductRelatedServices(
      productCode,
      services
    );
    const isProductDropdownDisabled = ShipmentModels.isProductDropdownDisabled(
      syncErrors,
      submitErrors,
      productsKeyValue
    );
    const isServiceDropdownDisabled = ShipmentModels.isServiceDropdownDisabled({
      syncErrors,
      submitErrors,
      services: servicesKeyValue,
      productCode,
      selectedCountry,
    });
    const isShipmentNotEditable =
      !shipment.stagingShipmentId &&
      (shipment.isVoided ||
        shipment.isPrinted ||
        !shipment.allowPrintShipment ||
        !ShipmentModels.isShipmentEditable(shipment, customerPrefs));

    return {
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.PRODUCT]:
        isProductDropdownDisabled || isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE]:
        isServiceDropdownDisabled || isShipmentNotEditable,
      [ShipmentEntity.GENERATE_CUSTOMS_DATA]:
        dropdownDisabled || noFailureDetails || isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE]:
        customsValueDisabled || isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
        .COUNTRY_CODE]:
        isShipmentEmpty || noManualAddressEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
        isShipmentEmpty || noManualAddressEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]:
        isShipmentEmpty || reference1ReadOnly || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
        .ORGANISATION]:
        isShipmentEmpty || noManualAddressEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET]:
        isShipmentEmpty || noManualAddressEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.LOCALITY]:
        isShipmentEmpty || noManualAddressEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN]:
        isShipmentEmpty || noManualAddressEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY]:
        isShipmentEmpty || noManualAddressEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION]:
        isShipmentEmpty || noManualAddressEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .CONTACT_NAME]:
        isShipmentEmpty || noManualContactDetailsEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .TELEPHONE]:
        isShipmentEmpty || noManualContactDetailsEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .CONTACT_NAME]:
        isShipmentEmpty || noManualContactDetailsEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .TELEPHONE]:
        isShipmentEmpty || noManualContactDetailsEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .EMAIL]:
        isShipmentEmpty || noManualContactDetailsEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .MOBILE]:
        isShipmentEmpty || noManualContactDetailsEntry || noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]:
        isShipmentEmpty || isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT]:
        isShipmentEmpty || isShipmentNotEditable,
      [ShipmentEntity.SHIPMENT_DATE]: isShipmentEmpty || isShipmentNotEditable,
      [Fields.REMEMBER_DATE]: isShipmentEmpty || isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2]:
        isShipmentEmpty || isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3]:
        isShipmentEmpty || isShipmentNotEditable,
      [ShipmentEntity.PROFILE_CODE]: noFailureDetails,
      [ShipmentEntity.SHIPMENT_TYPE]: noFailureDetails,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]:
        isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY]: isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE]:
        isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY]: isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO]:
        isShipmentNotEditable,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID]: isShipmentNotEditable,
      [ShipmentEntity.INVOICE.NUMBER_OF_PARCELS]: true,
      [ShipmentEntity.INVOICE.TOTAL_WEIGHT]: true,
      [ShipmentEntity.INVOICE.INVOICE_TYPE]: isShipmentNotEditable,
      [ShipmentEntity.INVOICE.EXPORT_REASON]: isShipmentNotEditable,
      [ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS]:
        isShipmentNotEditable,
      [ShipmentEntity.INVOICE.INVOICE_REFERENCE]:
        noFailureDetails || isShipmentNotEditable,
      [ShipmentEntity.INVOICE.SHIPPING_COST]:
        noFailureDetails || isShipmentNotEditable,
      packageContent: isShipmentNotEditable,
      [ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]: noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT]: noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.PRODUCT]:
        !returnProductValues.length || noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE]:
        !returnServiceValues.length || isShipmentNotEditable,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
        .COUNTRY_CODE]: noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
        noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
        .ORGANISATION]: noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET]:
        noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.LOCALITY]:
        noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN]:
        noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY]:
        noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION]:
        noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]:
        noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_1]: noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_2]: noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_3]: noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .CONTACT_NAME]: noFailureDetails,
      [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
        .TELEPHONE]: noFailureDetails,
      invoiceDetails: noFailureDetails || isShipmentNotEditable,
      [RETURN_DETAILS_SEARCH_FORM]: noFailureDetails || isShipmentNotEditable,
    };
  }
);

export const getCommonRequiredFields = createSelector(
  ShipmentSelectors.getCommonRequiredFields,
  ShipmentSelectors.getShipmentFormValues,
  ShipmentSelectors.getSelectedCountry,
  (commonRequiredFields, formValues, selectedCountry) => {
    const countryCode = getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    );
    const isPostcodeRequired = getValue(selectedCountry, "isPostcodeRequired");

    return {
      ...commonRequiredFields,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
        countryCode && isPostcodeRequired,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
        .COUNTRY_CODE]: true,
    };
  }
);

export const getAddressBookRequiredFields = createSelector(
  ShipmentSelectors.getSelectedCountry,
  getCommonRequiredFields,
  ShipmentModels.getAddressBookRequiredFields
);

export const getAddressBookErrors = createSelector(
  ShipmentSelectors.getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  ShipmentSelectors.getSelectedCountry,
  AuthSelectors.getAuthUser,
  UmsSelectors.getCustomer,
  getSearchAddressDetails(DELIVERY_DETAILS_SEARCH_FORM),
  ReferenceSelectors.getCountries,
  getAddressBookRequiredFields,
  ShipmentModels.getAddressBookErrors
);

export const getRequiredFields = createSelector(
  ShipmentSelectors.getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  ReferenceSelectors.getActiveInboundService,
  getCommonRequiredFields,
  ShipmentSelectors.getSelectedCountry,
  ShipmentSelectors.getCustomsFieldsConfig,
  ShipmentSelectors.isVisibleDestinationTaxIdRegNo,
  ShipmentSelectors.getInvoiceRequiredFields,
  ShipmentSelectors.getSelectedReturnCountry,
  UmsSelectors.getPreferences,
  ProfilesSelectors.getActiveProfile,
  UmsSelectors.getCustomer,
  (
    formValues,
    selectedOutboundService,
    selectedInboundService,
    commonRequiredFields,
    selectedCountry,
    customsFieldsFlags,
    isVisibleDestinationTaxIdRegNo,
    invoiceRequiredFields,
    selectedReturnCountry,
    preferences,
    profile,
    customer
  ) => {
    const requiredFields = ShipmentModels.getShipmentRequiredFields(
      formValues,
      selectedOutboundService,
      selectedInboundService,
      commonRequiredFields,
      selectedCountry,
      customsFieldsFlags,
      isVisibleDestinationTaxIdRegNo,
      invoiceRequiredFields,
      selectedReturnCountry,
      preferences,
      profile,
      customer
    );

    set(requiredFields, Fields.CUSTOMER_REF_1, true);

    return requiredFields;
  }
);

export const getAllowedFields = createSelector(
  getShipment,
  ShipmentSelectors.getCustomsFieldsConfig,
  getRequiredFields,
  ShipmentSelectors.isVisibleDestinationTaxIdRegNo,
  ShipmentSelectors.getShipmentFormValues,
  ShipmentSelectors.isCustomsFieldsVisible,
  UmsSelectors.getSecuritySettings,
  ReferenceSelectors.getActiveOutboundService,
  ShipmentSelectors.getShipmentTypes,
  UmsSelectors.getPreferences,
  ProfilesSelectors.getActiveProfile,
  UmsSelectors.getCustomerPrefs,
  UmsSelectors.getCustomer,
  (
    shipment,
    { deliveryDescriptionHidden },
    requiredFields,
    isAllowedDestinationTaxIdRegNo,
    formValues,
    isCustomsFieldsAllowed,
    securitySettings,
    selectedService,
    shipmentTypes,
    preferences,
    profile,
    customerPrefs,
    customer
  ) => {
    const allowedFields = ShipmentModels.getCommonAllowedFields(
      { deliveryDescriptionHidden },
      requiredFields,
      isAllowedDestinationTaxIdRegNo,
      formValues,
      isCustomsFieldsAllowed,
      securitySettings,
      selectedService,
      shipmentTypes,
      preferences,
      profile,
      customer
    );
    const isShipmentNotEditable =
      shipment.isVoided ||
      shipment.isPrinted ||
      !shipment.allowPrintShipment ||
      !ShipmentModels.isShipmentEditable(shipment, customerPrefs);

    set(allowedFields, "searchAddress.OUTBOUND_CONSIGNMENT", false);
    set(allowedFields, "clearButton", true);
    set(allowedFields, Fields.CUSTOMER_REF_1, true);
    set(
      allowedFields,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
      false
    );

    if (!shipment.stagingShipmentId && isShipmentNotEditable) {
      set(allowedFields, "editExporter", false);
      set(allowedFields, "editImporter", false);
    }

    return allowedFields;
  }
);

export const getShipmentDetailsReview = createSelector(
  ShipmentSelectors.getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  getAllowedFields,
  ShipmentSelectors.getShipmentProducts,
  ShipmentSelectors.getShippingFreightCost,
  ShipmentSelectors.getParcelsTotalValue,
  ShipmentSelectors.getTouchedErrorFields(OUTBOUND_CONSIGNMENT),
  ShipmentModels.getShipmentDetailsReview
);
