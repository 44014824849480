import set from "lodash/set";
import { createSelector } from "reselect";

import { getSearchAddressDetails } from "~/components/SearchAddress/selectors";
import {
  DELIVERY_DETAILS_SEARCH_FORM,
  OUTBOUND_CONSIGNMENT,
  ShipmentEntity,
} from "~/constants/forms";
import { ShipmentModels } from "~/models";
import { ShipmentSelectors } from "~/pages/Shipment/redux";
import {
  AuthSelectors,
  ProfilesSelectors,
  ReferenceSelectors,
  UmsSelectors,
} from "~/redux";
import { getValue } from "~/utils/object";

export const getDisabledFields = createSelector(
  UmsSelectors.getAdvancedCustomerConfiguration,
  ShipmentSelectors.getCommonDisabledFields,
  ({ noManualAddressEntry, reference1ReadOnly }, commonFields) => {
    return {
      ...commonFields,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
        .COUNTRY_CODE]: noManualAddressEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
        noManualAddressEntry,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]: reference1ReadOnly,
    };
  }
);

export const getCommonRequiredFields = createSelector(
  ShipmentSelectors.getCommonRequiredFields,
  ShipmentSelectors.getShipmentFormValues,
  ShipmentSelectors.getSelectedCountry,
  (commonRequiredFields, formValues, selectedCountry) => {
    const countryCode = getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    );
    const isPostcodeRequired = getValue(selectedCountry, "isPostcodeRequired");

    return {
      ...commonRequiredFields,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
        countryCode && isPostcodeRequired,
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
        .COUNTRY_CODE]: true,
    };
  }
);

export const getAddressBookRequiredFields = createSelector(
  ShipmentSelectors.getSelectedCountry,
  getCommonRequiredFields,
  ShipmentModels.getAddressBookRequiredFields
);

export const getAddressBookErrors = createSelector(
  ShipmentSelectors.getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  ShipmentSelectors.getSelectedCountry,
  AuthSelectors.getAuthUser,
  UmsSelectors.getCustomer,
  getSearchAddressDetails(DELIVERY_DETAILS_SEARCH_FORM),
  ReferenceSelectors.getCountries,
  getAddressBookRequiredFields,
  ShipmentModels.getAddressBookErrors
);

export const getAllowedFields = createSelector(
  ShipmentSelectors.getCustomsFieldsConfig,
  ShipmentSelectors.getShipmentRequiredFields,
  ShipmentSelectors.isVisibleDestinationTaxIdRegNo,
  ShipmentSelectors.getShipmentFormValues,
  ShipmentSelectors.isCustomsFieldsVisible,
  UmsSelectors.getSecuritySettings,
  ReferenceSelectors.getActiveOutboundService,
  ShipmentSelectors.getShipmentTypes,
  UmsSelectors.getPreferences,
  ProfilesSelectors.getActiveProfile,
  UmsSelectors.getCustomer,
  (
    { deliveryDescriptionHidden },
    requiredFields,
    isAllowedDestinationTaxIdRegNo,
    formValues,
    isCustomsFieldsAllowed,
    securitySettings,
    selectedService,
    shipmentTypes,
    preferences,
    profile,
    customer
  ) => {
    const allowedFields = ShipmentModels.getCommonAllowedFields(
      { deliveryDescriptionHidden },
      requiredFields,
      isAllowedDestinationTaxIdRegNo,
      formValues,
      isCustomsFieldsAllowed,
      securitySettings,
      selectedService,
      shipmentTypes,
      preferences,
      profile,
      customer
    );

    set(allowedFields, "searchAddress.OUTBOUND_CONSIGNMENT", true);
    set(allowedFields, "clearButton", true);

    return allowedFields;
  }
);

export const getShipmentDetailsReview = createSelector(
  ShipmentSelectors.getShipmentFormValues,
  ReferenceSelectors.getActiveOutboundService,
  getAllowedFields,
  ShipmentSelectors.getShipmentProducts,
  ShipmentSelectors.getShippingFreightCost,
  ShipmentSelectors.getParcelsTotalValue,
  ShipmentSelectors.getTouchedErrorFields(OUTBOUND_CONSIGNMENT),
  ShipmentModels.getShipmentDetailsReview
);
