import { get, isString } from "lodash";

import { REGEX } from "@dpdgroupuk/mydpd-joi-validator";

import { GB, IE, POSTCODE_IS_NOT_VALID, US } from "~/constants/strings";
import { PostcodeModels } from "~/models";

import { createErrorBody } from "./additionalValidations";
import { FIRST_TWO_CHARACTERS } from "./regex";

const BIC7_OPTIONS = {
  A: "([A-Z])",
  B: "([A-Z])",
  N: "([0-9])",
  O: "([0-9])",
  "?": "([A-Z])([0-9])",
  X: "([A-Z])([0-9])",
};

const generateRegexForBIC7PostCodePattern = (bic7PostCodePattern = "") => {
  const regex = Array.from(bic7PostCodePattern).reduce(
    (acc, char) => (acc += BIC7_OPTIONS[char]),
    "^"
  );
  return regex.concat("$");
};

const generateRegexPatterns = postcodePattern => {
  const patterns = !Array.isArray(postcodePattern)
    ? Array.from(postcodePattern).split(",")
    : postcodePattern;

  return patterns
    .filter(pattern => isString(pattern))
    .map(pattern => generateRegexForBIC7PostCodePattern(pattern));
};

const postcodeIsNotValidError = postcodePath =>
  createErrorBody(postcodePath, POSTCODE_IS_NOT_VALID);

const validateInternationalPostcodeWithPatterns = (
  postcodePattern,
  postcodePath,
  postcodeValue
) => {
  if (postcodePattern) {
    const regexPatterns = generateRegexPatterns(postcodePattern);
    const isValid = regexPatterns.some(pattern =>
      RegExp(pattern).test(postcodeValue)
    );

    if (!isValid) {
      return postcodeIsNotValidError(postcodePath);
    }
  }
};

const skipPostcodePatternCheck = postcodePattern => {
  let withoutPostcodeValidation = false;

  if (Array.isArray(postcodePattern) && postcodePattern.length) {
    withoutPostcodeValidation = postcodePattern?.some(
      pattern => pattern.trim() === ""
    );
  }

  return withoutPostcodeValidation;
};

const validateInternationalPostcode = (
  flagPostCodeNo,
  postcodeValue,
  countryCode,
  postcodePattern,
  postcodePath
) => {
  if (flagPostCodeNo) {
    return;
  }

  if (skipPostcodePatternCheck(postcodePattern)) return;

  if (countryCode === US && postcodeValue.match(FIRST_TWO_CHARACTERS)) {
    const postcodeWithoutFirstTwoCharacters = postcodeValue.substring(2);
    return validateInternationalPostcodeWithPatterns(
      postcodePattern,
      postcodePath,
      postcodeWithoutFirstTwoCharacters
    );
  }

  const postcodeWithoutCountryCode = postcodeValue.startsWith(countryCode)
    ? postcodeValue.substring(2)
    : postcodeValue;
  return validateInternationalPostcodeWithPatterns(
    postcodePattern,
    postcodePath,
    postcodeWithoutCountryCode
  );
};

const postcodeValidation = (props, countryValue, postcodePath) => {
  const postcodeValue = get(props, `values.${postcodePath}`);

  if (!postcodeValue) {
    return;
  }

  const flagPostCodeNo = get(countryValue, "flagPostCodeNo", "");
  const countryCode = get(countryValue, "countryKey", "");
  const postcodePattern = get(countryValue, "postcodePattern", "");

  if (countryCode === GB) {
    if (PostcodeModels.isGBPostCodeFormat(postcodeValue)) {
      return;
    }

    return postcodeIsNotValidError(postcodePath);
  }

  if (countryCode === IE) {
    if (REGEX.IE_POSTCODE_PATTERN.test(postcodeValue)) {
      return;
    }

    return postcodeIsNotValidError(postcodePath);
  }

  return validateInternationalPostcode(
    flagPostCodeNo,
    postcodeValue,
    countryCode,
    postcodePattern,
    postcodePath
  );
};

export default postcodeValidation;
