import PropTypes from "prop-types";
import classNames from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { change, Field, propTypes, reduxForm } from "redux-form";
import noop from "lodash/noop";

import productDescriptionSchema from "~/models/validators/productDescriptionSchema";
import { ShipmentActions, ShipmentSelectors } from "~/pages/Shipment/redux";

import createValidator from "~/utils/joiReduxForm";

import { Button, FormControl } from "@dpdgroupuk/mydpd-ui";

import ProductDescriptionsListItem from "~/components/ProductDescriptionsListItem";
import {
  PRODUCT_BOOK_SEARCH_FORM,
  PRODUCT_DESCRIPTIONS_FORM,
  ProductDescriptionsEntity,
  SEARCH_CRITERIA_VALUE,
} from "~/constants/forms";
import * as S from "~/constants/strings";

import styles from "./ProductDescriptionsContent.module.scss";
import SearchProduct from "~/components/SearchProduct/SearchProduct";
import { withNotifier } from "@dpdgroupuk/mydpd-app";
import { ProductBookActions } from "~/redux";
import { getValue } from "~/utils/object";

const ProductDescriptionsContent = ({
  products,
  setProductDescription,
  removeProductDescription,
  onFieldEntry,
  handleSubmit,
  onEnterPress,
  onSaveChanges,
  isEditingProductDescription,
  setIsEditingProductDescription,
  onProductBookSelectionChange,
}) => (
  <Container>
    <Row>
      <Col>
        <SearchProduct
          onFieldEntry={onFieldEntry}
          form={PRODUCT_BOOK_SEARCH_FORM}
          onClear={noop}
          onSelectionChange={onProductBookSelectionChange}
        />
        <div className="d-flex align-items-start">
          <Field
            component={FormControl.Input}
            label={S.PRODUCT_DESCRIPTION}
            name={ProductDescriptionsEntity.PRODUCT_DESCRIPTION}
            helperText={S.PLEASE_DESCRIBE_YOUR_PRODUCT}
            onBlur={onFieldEntry}
            maxLength={200}
            onKeyDown={onEnterPress}
          />
          <Button
            variant="primary"
            className="ml-3"
            onClick={handleSubmit(setProductDescription)}
          >
            {S.ADD}
          </Button>
        </div>
        {!!products?.length && (
          <div className={styles.container}>
            <p className={classNames(styles.title, "mb-3")}>
              {S.CURRENT_DESCRIPTIONS}
            </p>
            <ul className={styles.currentDescriptionList}>
              {products.map(({ productDescription }, index) => (
                <ProductDescriptionsListItem
                  key={`${productDescription}${index}`}
                  sequenceNumber={index + 1}
                  text={productDescription}
                  onRemove={removeProductDescription}
                  isRemoveBtnVisible={products.length > 1}
                  onSaveChanges={onSaveChanges}
                  isEditingProductDescription={isEditingProductDescription}
                  setIsEditingProductDescription={
                    setIsEditingProductDescription
                  }
                />
              ))}
            </ul>
          </div>
        )}
      </Col>
    </Row>
  </Container>
);

ProductDescriptionsContent.propTypes = {
  ...propTypes,
  onFieldEntry: PropTypes.func,
  handleSubmit: PropTypes.func,
  setProductDescription: PropTypes.func,
  removeProductDescription: PropTypes.func,
  onEnterPress: PropTypes.func,
  onSaveChanges: PropTypes.func,
  setIsEditingProductDescription: PropTypes.func,
  onProductBookSelectionChange: PropTypes.func,
  products: PropTypes.array,
  isEditingProductDescription: PropTypes.bool,
};

export default compose(
  withNotifier,
  connect(
    (state, { pageConfig }) => ({
      products: ShipmentSelectors.getProducts(state, pageConfig),
      requiredFields: ShipmentSelectors.getProductDescriptionRequiredFields(
        state,
        pageConfig
      ),
    }),
    (dispatch, { pageConfig, notifier, isEditingProductDescription }) => ({
      setProductDescription: () =>
        dispatch(ShipmentActions.setProductDescription(pageConfig)),
      onEnterPress: event => {
        if (event.code === "Enter") {
          event.preventDefault();

          if (event.target.value.trim()) {
            dispatch(ShipmentActions.setProductDescription(pageConfig));
          }
        }
      },
      removeProductDescription: (event, productDescriptionIndex) => {
        event.preventDefault();
        dispatch(
          ShipmentActions.removeProductDescription(
            pageConfig,
            productDescriptionIndex
          )
        );
      },
      onSaveChanges: (value, index) =>
        dispatch(
          ShipmentActions.insertProductDescription(value, index, pageConfig)
        ),
      onProductBookSelectionChange: notifier.runAsync(
        async ({ productBookId }) => {
          const { data } = await dispatch(
            ProductBookActions.fetchProductBookById(productBookId)
          );

          if (!isEditingProductDescription) {
            dispatch(
              change(
                PRODUCT_DESCRIPTIONS_FORM,
                ProductDescriptionsEntity.PRODUCT_DESCRIPTION,
                getValue(
                  data,
                  ProductDescriptionsEntity.PRODUCT_DESCRIPTION,
                  ""
                )
              )
            );
            dispatch(
              change(PRODUCT_BOOK_SEARCH_FORM, SEARCH_CRITERIA_VALUE, undefined)
            );
          }
        }
      ),
    })
  ),
  reduxForm({
    form: PRODUCT_DESCRIPTIONS_FORM,
    validate: (values, props) =>
      createValidator(productDescriptionSchema)(values, props),
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.setIsEditingProductDescription(false);
    },
  })
)(ProductDescriptionsContent);
